import { InstalmentPayments, Order } from '../payload-types';
import { calculatePrice } from './price';

type GetInitialAmountToPayParams = {
	total: number;
	instalmentPaymentsEnabled?: boolean;
	singleInstalmentAmountToPay?: Order['singleInstalmentAmountToPay'];
	chosenInstalmentPaymentsCount?: Order['chosenInstalmentPaymentsCount'];
};

// used if instalment payments are enabled on order
export function getInitialAmountToPay({
	total,
	instalmentPaymentsEnabled,
	singleInstalmentAmountToPay,
	chosenInstalmentPaymentsCount,
}: GetInitialAmountToPayParams): number {
	if (!total) {
		throw new Error('Cannot calculate amount to pay because total is missing');
	}
	if (instalmentPaymentsEnabled && chosenInstalmentPaymentsCount && singleInstalmentAmountToPay) {
		const instalmentPaymentsTotal =
			(chosenInstalmentPaymentsCount - 1) * singleInstalmentAmountToPay;

		return calculatePrice(total! - instalmentPaymentsTotal);
	}

	return total!;
}
