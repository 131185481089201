import _ from 'lodash';
import { VAT, VAT_MULTIPLIER } from './config';
import { Order } from '../payload-types';

export const roundPrice = (price: number): number => {
	return _.round(price, 2);
};

export const calculatePrice = (price: number, addVat?: boolean): number => {
	if (addVat) {
		return roundPrice(price * VAT_MULTIPLIER);
	}

	return roundPrice(price);
};

export const getSingleInstalmentAmountToPay = (totalPrice: number, shares: number) => {
	return calculatePrice(totalPrice / shares);
};

export function formatCurrency({
	price,
	currency = 'EUR',
	locale,
}: {
	price: number;
	currency?: 'EUR';
	locale?: Order['language'];
}) {
	if (!locale) {
		locale = 'et';
	}
	return price.toLocaleString(locale, {
		currency,
		style: 'currency',
		useGrouping: true,
		minimumFractionDigits: 2,
		// @ts-expect-error trailingZeroDisplay
		trailingZeroDisplay: 'stripIfInteger',
	});
}
